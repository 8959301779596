import { Editor } from '@tinymce/tinymce-react';
import React, { useState } from 'react';

const Tinymce = props => {

  const [content, setContent] = useState(props.content)

  let toolbar_buttons = 'undo redo | cut copy paste | bold italic underline blockquote | bullist numlist | link | code help'

  if(props.extended)
    toolbar_buttons = 'undo redo | cut copy paste | styleselect | fontsizeselect bold italic underline blockquote | alignleft aligncenter alignright alignjustify | bullist numlist | link | image media | visualblocks code help'

  if(props.pages)
    toolbar_buttons = 'undo redo | cut copy paste | styleselect | fontsizeselect bold italic underline blockquote | alignleft aligncenter alignright alignjustify | bullist numlist | link | image media | visualblocks code help'

  if(props.minimal)
    toolbar_buttons = ''

  return <div style={{width: '79%', marginLeft: '20%'}}>
    <input type="hidden" name={props.name} value={content} />
    <Editor
     apiKey="6u0wy19k9c47xjes0ebf0y2hwl0xyoac0fnx3wqugaeu9wwu"
     initialValue={props.content}
     init={{
       height: props.height,
       menubar: false,
       inline: props.minimal,
       formats: {
          subtitle1: { block: 'h2' },
          subtitle2: { block: 'h3' },
          subtitle3: { block: 'h4' }
        },
        style_formats: [
          { title: 'Header 1', format: 'subtitle1' },
          { title: 'Header 2', format: 'subtitle2' },
          { title: 'Header 3', format: 'subtitle3' }
      ],
       plugins: [
         'image link code paste lists advlist wordcount media help visualblocks quickbars anchor',

       ],
       // external_plugins: {
       //   'abbr': '/tinymce_external_plugins/abbr/plugin.js'
       // },
       // content_css: '/tinymce_custom_v2.css',
       quickbars_selection_toolbar: props.minimal ? 'bold italic styleselect' : 'bold italic styleselect | quicklink blockquote',
       quickbars_insert_toolbar: false,
       quickbars_image_toolbar: false,
       toolbar: toolbar_buttons
     }}
     onEditorChange={(editorContent) => setContent(editorContent)}
   />
   </div>
}
export default Tinymce
