$(document).ready(function(){

  $(".select_box_title").click(function(){
    $(this).next().slideToggle(200);
  });

  $(".category_switch li a").click(function(e){
    e.preventDefault();
    $(this).parent().parent().slideToggle(200);
    $(this).parent().parent().parent().find(".select_box_title p").text($(this).text());
    $(this).parent().parent().parent().find(".select_box_title input").val($(this).attr("data-id"));
  });

  $(".category_switch li a").each(function(){
    if($(this).attr("data-selected") === "true"){
      $(this).parent().parent().parent().find(".select_box_title p").text($(this).text());
      $(this).parent().parent().parent().find(".select_box_title input").val($(this).attr("data-id"));
    }
  });

  $(".theme_switcher li a").click(function(e){
    $(this).parent().parent().slideToggle(200);
    $(this).parent().parent().parent().find(".select_box_title p").text($(this).text());
  });


});
