require('lightbox2')
require('jquery-ui-bundle')
require('selectize/')

$(document).ready(function() {
  const button = document.querySelector('.hamburger_button');
  const menu = document.querySelector('nav');
  const bodyNoScroll = document.querySelector('body');

  button.addEventListener('click', () => {
    button.classList.toggle('-menu-open');
    menu.classList.toggle('-open');
    bodyNoScroll.classList.toggle('no-scroll');
  });

  $('.search_icon').click(function(){
    $(this).toggleClass('active')
    if($(window).width() > 1100) {
      $('.search_box.before').toggleClass('active')
      $('.search_box.before input').focus()
    } else {
      $('.search_box.after').toggleClass('active')
      $('.search_box.after input').focus()
    }
  });

  // $('.main_menu span').click(function() {
  //   var this_open = $(this).parent().hasClass('active');
  //   $('.has_dropdown').removeClass('active');
  //   if(this_open) {
  //     $(this).parent().removeClass('active');
  //   } else {
  //     $(this).parent().addClass('active');
  //   }
  // });


  if ($(window).width() > 1120) {
    $('.main_menu li.has_dropdown').hover(function() {
    $(this).toggleClass('active');
    });
  }  else {
    $('.main_menu li.has_dropdown').click(function() {
      $(this).toggleClass('active');
    });
  }

  $('.selectize').selectize({});

  $('.vertical_social_media p').click(function(e) {
    $(this).next().slideToggle(100);
    $(this).toggleClass("active");
  });

  $('.member_id').click(function() {
    $(this).parent().next().slideToggle(100);
    $(this).toggleClass('active');
  });

  $('.button_showMembers a').click(function(){
    $('.dropdown_members_list').fadeToggle()
  });

  $('.title_sort').click(function() {
    $('#sort').val('title');
    $('#library_form').submit();
  })
  $('.year_sort').click(function() {
    $('#sort').val('');
    $('#library_form').submit();
  })

  if ($('.wrapper.row.blog_index .row.main_article').length) {
    var mainPostHeight = $('.wrapper.row.blog_index .row.main_article').outerHeight();
    var secondPostHeight = $('.wrapper.row.blog_index .col_4.second_post').outerHeight();
    var postsUp = $('.wrapper.row.blog_index .col_4.up');
    var postHomebox = $('.col_4.homebox');
    
    if (secondPostHeight > mainPostHeight) {
      postHeightDiff = mainPostHeight - secondPostHeight + 50;
      postsUp.css("margin-top", postHeightDiff + "px");
    } else {
      postHeightDiff = secondPostHeight - mainPostHeight;
      postHomebox.css("margin-top", postHeightDiff + "px");
    }
  }


});
